.dashboardContainer {
    display: flex;
    flex-wrap: wrap;
}

.sectionContainer {
    padding: 0 2rem;
}

.flex {
    display: flex;
    align-items: center;
}

.flexCenter {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.flexSpaceBetween {
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
}
.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.section {
    display: flex;
}

.sectionTitle {
    margin: 3rem 0 0.5rem 3rem;
}

.dashboardContentContainer {
  margin: 0.2rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 6px 0  rgba(0,0,0,0.2);
  /* width: 100%; */
}

.donutContainer {
    max-width: 300px;
}

.column {
    width: fit-content;
}

/* @media screen and (max-width: 600px) {
    .column {
        width: 100%;
    }
} */

.testGraphSize {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-gray);
    border-radius: 1rem;
    padding: 1rem;
    height: 100px;
}


/* product Box section======================================================*/
.productBoxIce {
    background-color: var(--color-ice-background);
    box-shadow: 3px 3px 0px 0px var(--color-ice-font);
}
.productBoxWater {
    background-color: var(--color-water-background);
    box-shadow: 3px 3px 0px 0px var(--color-ice-font);
}
.productBoxAlkaline {
    background-color: var(--color-alkaline-background);
    box-shadow: 3px 3px 0px 0px var(--color-ice-font);
}

.productBoxIce,
.productBoxWater,
.productBoxAlkaline {
    width: 100px;
    height: 100px;
    border-radius: 1rem;
    padding: 0.5rem;
    margin: 0.5rem;
}

.productIconIce {
    background-color: var(--color-ice-font);
}
.productIconWater {
    background-color: var(--color-water-font);
}
.productIconAlkaline {
    background-color: var(--color-alkaline-font);
}

.productIconIce,
.productIconWater,
.productIconAlkaline {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    color: #fff;
    border-radius: 1rem;
}
.amountContainer {
    display: flex;
    align-items: flex-end;
    margin: 1rem 0.2rem;
}
.productAmount {
    font-size: 2.5rem;
    font-weight: 900;
    color: #000;
    line-height: 1;
    padding-right: 0.2rem;
}

/* total customer section======================================================*/
.totalCustomerContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 300px;
    padding: 3rem 0;
}
.totalCustomerAmount {
    font-size: 6rem;
    font-weight: 900;
    color: #000;
    line-height: 1;
}

.totalCustomerContainerIce {
    background-color: var(--color-ice-background);
}
.totalCustomerContainerWater {
    background-color: var(--color-water-background);
}
.totalCustomerContainerAlkaline {
    background-color: var(--color-alkaline-background);
}

.totalCustomerContainerIce,
.totalCustomerContainerWater,
.totalCustomerContainerAlkaline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    margin: 1rem;
}

.iconName {
    font-size: 16px;
    font-weight: 900;
    padding-left: 0.5rem;
}

/*  section======================================================*/