.contentContainer {
    margin-bottom: 1rem;
    border-radius: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 6px 0  rgba(0,0,0,0.2);
}

.content {
    padding: 2rem;
}

.cardTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.iconContainer {
    background-color: var(--color-blue1);
}


.cardTitle div {
    font-size: 18px;
}

.titleLeft {
    display: flex;
    align-items: center;
}

.titleText {
    margin-left: 1rem;
}

.cardTable {
    margin-top: 1rem;
    width: 100%;
}

.headContainer {
    border-bottom: 1px solid var(--color-font-primary);
}

.cardTableLine {
    display: flex;
    justify-content: center;
    padding-top: 0.5rem;
}

.amount {
    width: 60%;
    text-align: end;
}
.amountSign {
    padding-left: 0.5rem;
    width: 40%;
}
.price {
    width: 60%;
}
.priceSign {
    padding-left: 0.5rem;
    width: 40%;
    text-align: start;
}

.barIce,
.barWater,
.barAlkaline {
    height: 1rem;
}
.barIce{
    background-color: var(--color-ice-background);
}
.barWater{
    background-color: var(--color-water-background);
}
.barAlkaline {
    background-color: var(--color-alkaline-background);
}

/* .deleteTab {
    position: absolute;
    background-color: #fff;
    top: 4rem;
    right: 1.5rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
    overflow: hidden;
    padding: 0.5rem;
    z-index: 2;
}

.pinInputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 190px;
    padding: 1rem 0;
    cursor: pointer;
}

.pinInput {
    height: 30px;
    border: 1px solid var(--color-gray);
    border-radius: 1rem;
    width: 140px;
}

.deleteBtn {
    padding: 0.5rem;
    border-radius: 1rem;
    cursor: pointer;
    border: none;
    background-color: var(--color-warning);
    color: #fff;
    text-align: center;
} */

.pinInput {
    height: 30px;
    border: 1px solid var(--color-gray);
    border-radius: 1rem;
    width: 130px;
    margin-left: 1rem;
    padding-left: 1rem;
}