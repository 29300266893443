.heading{
    display: flex;
    justify-content: space-between;
    /* background-color: aqua; */
    padding: 2rem 0;
}

.label{
    color: var(--color-gray);
}

.customerInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* @media only screen and (max-width: 992px) {
    .customerInfo {
        justify-content: flex-start;
    }
} */

.labelContainer {
    display: flex;
    align-items: center;
}

.summary {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.InputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--color-gray);
    border-radius: 1rem;
    padding: 1rem;
    margin: 4rem 0;
}

.numberInput {
    display: flex;
    align-items: center;
}

.numberInput input,
.textInputContainer input {
    height: 40px;
    border: 1px solid var(--color-gray);
    border-radius: 1rem;
    margin: 0 1rem;
    padding: 0 1rem;
}

.numberInput input {
    width: 70px;
}
.textInputContainer input {
    width: 37vw;
}

.customerTypeSelect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 1rem;
}

.customerTypeSelect select{
    font-size: 20px;
    color: var(--color-blue1);
    font-weight: 500;
    width: 200px;
}

.planSelect select {
    width: 150px;
}

.customerTypeSelect select,
.planSelect select {
    width: 200px;
    height: 50px;
    border: 1px solid var(--color-gray);
    border-radius: 1rem;
    /* margin: 0 1rem; */
    padding: 0 1rem;
    /* text-align: end; */
}

.numberInput div {
    padding: 0 1rem;
}

.badgeHolder {
    display: flex;
}

.divider {
    height: 2px;
    background-color: var(--color-font-primary);
    margin: 2rem 0;
}

.iconDesc {
    margin-left: 0.5rem;
    font-weight: 600;
}

.red {
    color: red;
    font-size: 20px;
}

.formBtnContainer {
    display: flex;
    justify-content: flex-end;
}

.formBtnSubmitLoading {
    padding: 1rem 2rem;
    margin: 1rem;
    border-radius: 1.5rem;
    border: none;
    color: #fff;
    background-color: var(--color-blue4);
}

.formBtnReset,
.formBtnSubmit {
    padding: 1rem 2rem;
    margin: 1rem;
    border-radius: 1.5rem;
    cursor: pointer;
}

.formBtnReset {
    border: 1px solid var(--color-blue1);
    background-color: var(--color-background);
    color: var(--color-font-primary);
}

.formBtnSubmit {
    border: none;
    background-color: var(--color-blue2);
    color: #fff;
}

.formBtnDisabled {
    padding: 1rem 2rem;
    margin: 1rem;
    border-radius: 1.5rem;
    border: none;
    background-color: var(--color-gray);
    color: #fff;
}

.max {
    text-align: center;
    /* padding-right: 15rem; */
}

.rechargeSummaryContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.rechargeSummary {
    display: flex;
}

.costContainer{
    width: 150px;
    text-align: end;
    font-size: 2rem;
}
.costContainerTotal{
    width: 150px;
    text-align: end;
    font-size: 2.4rem;
    font-weight: 700;
}

.summaryDivider {
    height: 2px;
    width: 250px;
    background-color: var(--color-gray);
}

.expand {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.costType {
    display: flex;
    align-items: center;
    font-size: 2rem;
}

.textInputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0 1rem 1rem;
}

.pinInputContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 0;
    cursor: pointer;
}

.pinInput {
    height: 50px;
    border: 1px solid var(--color-gray);
    border-radius: 1rem;
    width: 150px;
    margin-left: 1rem;
    padding-left: 1rem;
}

.userTable {
    display: flex;
    justify-content: center;
    text-align: center;
}

.userTableHead {
    min-width: 300px;
}

.accountEmail {
    margin: 0 1rem;
}