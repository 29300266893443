.undoTransactionContainer {
    position: absolute;
    background-color: #fff;
    top: 0.5rem;
    right: 1.5rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
    overflow: hidden;
    width: 300px;
    padding: 1rem;
  }

.undoTitle {
    font-weight: 700;
    padding-bottom: 0.5rem;
}

.undoButtonContainer {
    padding-top: 1rem;
}

.pinInputContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem 0;
    cursor: pointer;
}

.pinInput {
    height: 40px;
    border: 1px solid var(--color-gray);
    border-radius: 1rem;
    width: 150px;
    margin-left: 1rem;
    padding-left: 1rem;
}