.container {
    /* display: flex;
    align-items: center; */
    /* justify-content: space-between; */
    margin: 1rem 0;
    flex-wrap: wrap;
}

.searchBarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--color-gray);
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
    height: 60px;
}

.searchInput {
    min-width: 200px;
    border: none;
    font-family: Arial, FontAwesome;
}
.clearBtn {
    color: #fff;
    background-color: var(--color-gray);
    border-radius: 5rem;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clearBtn i {
    font-size: 1.6rem;
}


.clearBtn:hover {
    font-weight: 700;
}

.clearBtn:active {
    background-color: var(--color-blue1);
}

.magnifyingGlassIcon {
    padding: 0 0.5rem;
}

@media only screen and (max-width: 992px) {
    .searchBarContainer {
        width: 100%;
    }
  }