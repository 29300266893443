.feature h2,h3,h4{
    color: #173673;
}

.colorWhite {
    font-size: 1.5em;
    font-weight: bold;
    color: #fff;
}

.flexTitle,
.flexContainer,
.flexContainerFlipped,
.flexContainerAligned {
    max-width: 1080px;
    margin: 0 auto;
}

.flexTitle {
    padding: 2rem 1rem;
}

.flexContainer,
.flexContainerFlipped {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5rem 1rem;
}
.flexContainerAligned {
    display: flex;
    flex-wrap: no-wrap;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 992px) {
    .flexContainer,
    .flexContainerAligned {
        justify-content: center;
        flex-wrap: wrap;
    }
    .flexContainerFlipped {
        flex-direction: column-reverse;
        align-items: center;
    }
}

.bg {
    background-color: var(--color-background);
    /* TODO:fix to var(--color-something-later) */
}

.greenBg {
    background-color: #32D98B;
    /* TODO:fix to var(--color-something-later) */
}
.blueBg {
    background-color: #173673;
    color: #fff;
    /* TODO:fix to var(--color-something-later) */
}
.whiteBg {
    background-color: #fff;
    /* TODO:fix to var(--color-something-later) */
}

.textContainerBig {
    padding: 2rem;
}

@media only screen and (min-width: 996px) {
    .textContainerBig {
        width: 500px;
    }
}

.textContainer {
    max-width: 300px;
}

.title {
    display: flex;
    align-items: center;
    padding: 2rem 0;
}

.secondaryTitle {
    padding: 2rem 0;
}

.title h2 {
    padding-right: 1rem;
}

.sectionMainImg img {
    max-width: 500px;
    padding: 1rem;
}

@media only screen and (max-width: 500px) {
    .sectionMainImg img {
        width: 350px
    }
}

.sectionExtra {
    width: 250px;
    margin: 0 auto;
    padding: 3rem 0;
}

.sectionExtra img{
    width: 250px;
    object-fit: contain;
}

@media only screen and (min-width: 992px) {
    .sectionExtra
    .sectionExtra img {
        width: 250px;
        padding: 0rem;
    }
}

.premiumFeature {
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    background-color: #173673;
    padding: 1rem 2rem;
    border-radius: 3rem;
    color: #fff;
}
.premiumFeature i {
    font-size: 2rem;
    color: #32D98B;
    padding-right: 0.5rem;
}

.checkIcon {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.checkIcon i {
    font-size: 2rem;
    font-weight: 900;
    color: #32D98B;
    padding-right: 1rem;
}