.numberInputContainer {
    margin: 1rem;
    background-color: var(--color-gray);
    padding: 1rem;
}

.numberInput {
    display: flex;
}

.numberInputIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-blue1);
    color: white;
    border-radius: 2rem;
    font-size: 20px;
    padding: 0 1rem;
    margin: 0 1rem;
}

.numberInputBox {
    border: none;
}

.numberInputBox::placeholder {
    color: black;
}

.pricesContainer {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem ;
}
.priceCard {
    width: 32%;
}

@media screen and (max-width: 992px) {
    .pricesContainer {
        flex-direction: column;
    }
    .priceCard {
        width: 100%;
    }
}