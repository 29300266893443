@import "@aws-amplify/ui-react/styles.css";
:root {
  --amplify-fonts-default-variable: "Open Sans";
  --amplify-fonts-default-static: "Open Sans";
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.flex {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.cursorPointer {
  cursor: pointer;
}

.hidden {
  display: none;
}

.button {
  cursor: pointer;
}

.heading {
  font-size: 28px;
  font-weight: 600;
  padding: 0.4rem 0 1rem;
  margin: 0;
}

.logo {
  margin-right: 1rem;
}

.bold {
  font-weight: 700;
}

.icon,
.logo {
  width: 32px;
  height: 32px;
}

.content {
  margin: auto;
  max-width: 1600px;
}

.contentWithoutSideBar {
  background-color: var(--color-background);
}

.contentWithSideBarThin{
  margin-left: 72px;
}

.contentWithSideBar {
  margin-left: 250px;
}

.contentWithSideBarThin,
.contentWithSideBar {
  background-color: var(--color-background);
  /* padding: 2rem; */
  height: 100vh;
  transition: all 0.2s;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.contentWithSideBarThin::-webkit-scrollbar,
.contentWithSideBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.contentWithSideBarThin,
.contentWithSideBar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media screen and (max-width: 992px) {
  .contentWithSideBarThin,
  .contentWithSideBar {
      margin-left: 0;
  }
}

.contentContainer {
  margin: 0 2rem;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 6px 0  rgba(0,0,0,0.2);
}
.contentContainerColumns {
  display: flex;
  margin: 0 2rem;
  padding: 2rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 6px 0  rgba(0,0,0,0.2);
}

.contentColumn {
  width: 50%;
}

.contentColumnCenter{
  width: 50%;
  text-align: center;
}

.profilePage {
  background-color: var(--color-background);
}

.pageTitle {
  margin: 0 2rem;
  padding: 3rem 2rem 1rem 2rem;
}

/* Search Bar placeholder ================================== */
.searchBarPlaceholder {
  display: flex;
}
.searchBarText {
  padding-left: 1rem;
}


/* Tables ========================================================== */
.tableContainer {
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

th div {
  font-size: 12px;
  text-align: center;
}

.tableHead {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sortIcon {
  margin-left: 1rem;
}

.wordBreak {
  word-break: break-all;
}

table {
  font-size: 14px;
  border-collapse: collapse;
}
.tableRowEven,
.tableRowOdd,
.tableRowActive {
  cursor: pointer;
}
.tableRowEven th,
.tableRowOdd th {
  /* border: 2px solid var(--color-gray); */
  padding: 1rem 0.5rem;
  text-align: start;
}

.tableRowEven {
  background-color: var(--color-background);
}

.tableRowEven td,
.tableRowOdd td,
.tableRowActive td {
  padding: 1rem 0.5rem;
}

.tableRowActive td {
  background-color: var(--color-blue5);
  color: var(--color-blue1);
}

.tableActionContainer {
  display: flex;
  justify-content: center;
  /* cursor: pointer; */
}
.tableAction,
.tableActionActive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  cursor: pointer;
}

.tableActionActive {
  background-color: var(--color-gray);
}

.tableActionContainer:hover .tableAction {
  background-color: var(--color-gray);
}

/* badge */
.badgeContainer {
  display: flex;
  margin: 0.5rem 0;
}

.badgeColumn {
  display: flex;
  flex-wrap: wrap;
}
.badgeWater,
.badgeIce,
.badgeAlkaline,
.badgeGray,
.badgePrimary {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 0.2rem 0.6rem;
  border-radius: 2rem;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.iconWater,
.iconIce,
.iconAlkaline,
.iconPlusMinus,
.iconPrimary {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
  width: 50px;
  height: 50px;
  margin: 0 1rem;
}

@media screen and (max-width: 992px){
  .iconWater,
  .iconIce,
  .iconAlkaline,
  .iconPlusMinus,
  .iconPrimary {
    width: 24px;
    height: 24px;
  }
}

.badgeWater,
.iconWater {
  background-color: var(--color-water-background);
  color: var(--color-water-font);
}
.badgeIce,
.iconIce {
  background-color: var(--color-ice-background);
  color: var(--color-ice-font);
}
.badgeAlkaline,
.iconAlkaline {
  background-color: var(--color-alkaline-background);
  color: var(--color-alkaline-font);
}
.badgeGray,
.iconPlusMinus {
  background-color: var(--color-gray);
  /* color: #000; */
  cursor: pointer;
}
.badgePrimary,
.iconPrimary {
  background-color: var(--color-blue2);
  color: #fff;
}

.searchBarAndPageSize {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 992px){
  .hiddenForMobile {
    display: none;
  }
}
@media screen and (min-width: 992px){
  .hiddenForDesktop {
    display: none;
  }
}

.paginationPosition {
  display: flex;
  justify-content: center;
}

.totalAmountOfSale {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .totalAmountOfSale {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.relative {
  position: relative;
}

.deleteTab {
  position: absolute;
  background-color: #fff;
  top: 5rem;
  right: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  overflow: hidden;
  padding: 2rem;
  z-index: 2;
}

.pinInputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 190px;
  padding: 1rem 0;
  cursor: pointer;
}

.pinChangeInputContainer {
  align-items: center;
  justify-content: space-between;
  width: 190px;
  padding: 1rem 0;
  cursor: pointer;
}

.pinInput {
  height: 30px;
  border: 1px solid var(--color-gray);
  border-radius: 1rem;
  width: 140px;
}

.deleteBtn {
  display: flex;
  justify-content: center;
}

.secondaryBtn,
.resetBtn,
.deleteBtn,
.primaryBtn,
.disabledBtn {
  margin: 0.2rem 0;
  padding: 0.5rem;
  border-radius: 1rem;
  cursor: pointer;
  border: none;
  text-align: center;
}

.primaryBtn {
  background-color: var(--color-blue1);
  color: #fff;
}

.secondaryBtn {
  background-color: var(--color-gray);
  color: var(--color-blue1)
}

.resetBtn {
  border: 1px solid var(--color-font-primary);
  background-color: var(--color-background);
  color: var(--color-font-primary);
}

.deleteBtn {
  background-color: var(--color-warning);
  color: #fff;
}

.disabledBtn {
  background-color: var(--color-gray);
  color: #fff;
  cursor: not-allowed;
}

.titleWithIcon {
  display: flex;
}

.iconInTitle {
  margin-right: 1rem;
}
.rightMargin {
  margin-right: 0.5rem;
}

.animatedCanvasContainer {
  position: absolute;
  z-index: -1;
  height: 1400px;
  width: 100vw;
  opacity: 0.7;
  /* opacity determines dramatic affect of the background color animation */
}

.animatedCanvas {
  width: 100%;
  height: 100%;
}

.featureCardIcon{
  font-size: 3.2rem;
  background: linear-gradient(90deg, #056CF2 0%, #1D96F8 10%, #00C1F4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.actionContainer {
  position: absolute;
  background-color: #fff;
  top: 0.5rem;
  right: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  overflow: hidden;
  width: 190px;
  padding: 0.5rem 0;
}

.qrcodeContainer {
  display: flex;
  justify-content: center;
  padding: 2rem;
}
.qrcodeSVG {
  background-color: var(--color-background);
  width: 80vw;
  height: 80vh;
}
.qrcodeDesc {
  text-align: center;
}

.customerListSearchNotFound,
.customerListNoCustomer {
  padding: 5rem 0;
  background-color: var(--color-background);
}

.customerListSearchNotFound div,
.customerListNoCustomer div {
  font-size: 1.6rem;
}

.customerListSearchNotFound {
  display: flex;
  justify-content: center;
}

.customerListNoCustomer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customerListGuideContainer {
  display: flex;
  padding: 2rem;
}

.customerListGuide {
  display: flex;
  padding: 0 1rem;
}