/* locally shared navbar things */
.publicNavbarContainer,
.profileNavbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 72px;
    top:0;
    position: sticky;
    background-color: #fff;
    box-shadow:  0 6px 4px -4px rgba(0,0,0,0.2); 
    z-index: 9;
}

@media screen and (min-width:992px){
    .profileNavbarContainer{
        display: none;
    }
}

.mobileNavbar {
    display: flex;
    align-items: center;
}

.publicNavbar,
.profileNavbar {
    display: none;
}

/* BURGER MENU LIST DROP DOWNS */
.burger {
    background-color: var(--color-blue2);
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-items: center;
    transition: all 0.4s ease;
}
.burgerNavList {
    text-align: center;
}
.burgerBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    padding: 2rem 0;
}
.burgerBtn div {
  font-size: 16px;
}
.burgerBtn:hover {
    color: var(--color-orange)
}
.burgerBtnIcon{
    margin-left: 1.5rem;
}


@media screen and (min-width: 760px) {
  .burgerBtn div {
    font-size: 24px;
  }
  .burgerBtnIcon{
    margin-left: 3rem;
  }
}
@media screen and (min-width: 992px) {
    .mobileNavbar {
        display: none;
    }
    .publicNavbar,
    .profileNavbar {
        display: flex;
    }
}
/* TODO: */
.logoContainer,
.logoContainerSideBar {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* margin: 0 0 0 1rem; */
    padding: 1rem 2rem;
    margin: 1rem 0;
    cursor: pointer;
}
.logoContainerSideBar {
  background-color: #fff;
  margin-bottom: 8rem;
  /* padding: 0 2rem 0 0 ; */
}

.logoText {
  font-size: 2.2rem;
  font-weight: 900;
  background: linear-gradient(to right, var(--color-blue1), var(--color-blue3), var(--color-blue3), var(--color-blue4));
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0,0,0,0);
}

.logoAndExpenderContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}

@media screen and (max-width: 992px) {
    .logoText {
        display: none;
    }
}

.loginBtn {
    background-color: var(--color-blue1);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    cursor: pointer;
    margin: 0 6rem;
}

.navList {
    display: flex;
    align-items: center;
}

.navListBtn {
    cursor: pointer;
    border: none;
    position: relative;
    padding: 0.5rem 1rem;
    margin: 0 1rem;
  }
  .navListBtn:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: var(--color-blue2);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  .navListBtn:hover:after {
    width: 100%; 
    left: 0;
  }
  .navListBtn:active {
    color: var(--color-orange);
  }

/* .sideBarNavBtn {
  margin: 1.5rem 0 1.5rem 1.5rem;
} */


/* .sideBarNavBtn {
  justify-content: flex-start;
}

.sideBarNavBtnThin {
  justify-content: center;
} */

.sideBarNavBtnThin,
.sideBarNavBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border: none;
  position: relative;
  padding: 1rem 2rem;
  margin: 1rem 0;
  }
  .sideBarNavBtnTitle {
    padding-left: 1rem;
    white-space: nowrap;
  }
  .sideBarNavBtnThin:hover,
  .sideBarNavBtn:hover {
    font-weight: 600;
  }
  .sideBarNavBtnThin:after,
  .sideBarNavBtn:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    background: var(--color-blue1);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  /* TODO: */
  /* .sideBarNavBtnThin:hover:after{
    width: 90%; 
    right: 0;
  }

  .sideBarNavBtn:hover:after {
    width: 90%; 
    right: 0;
  } */
  .sideBarNavBtnThin:active,
  .sideBarNavBtn:active {
    color: var(--color-blue1);
  }


  .activePageThin {
    border-radius: 0;
  }

  .activePage {
    border-radius: 4rem 0rem 0rem 4rem;
  }

  .activePageThin,
  .activePage {
    color: var(--color-blue1);
    background-color: var(--color-background);
    font-weight: 600;
    border-right: 2px solid var(--color-blue1);
  }
  .notActivePage {
    border-right: 2px solid #fff;
    font-weight: 400;
  }

  .toggler{
    /* ALWAYS KEEPING THE TOGGLER OR THE CHECKBOX ON TOP OF EVERYTHING :  */
      z-index: 3;
      height: 40px;
      width: 40px;
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      opacity: 0;
      margin: 0;
  }
  
  .hamburger{
      z-index:2;
      position: absolute;
      top: 16px;
      right: 16px;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  /* MIDDLE LINE OF THE HAMBURGER */
  .hamburger > div{
      position: relative;
      top: 0;
      left: 0;
      background: black;
      height: 3px;
      width: 80%;
      transition: all  0.4s ease;
      border-radius: 1rem;
  }
  /* TOP AND BOTTOM LINES OF THE HAMBURGER*/
  .hamburger > div::before,
  .hamburger > div::after{
      content: '';
      position: absolute;
      top: -10px;
      background: black;
      width: 100%;
      height: 3px;
      transition: all  0.4s ease;
      border-radius: 1rem;
  }
  .hamburger > div::after{
      top: 10px;
  }
  /* Hamburger Changed State to X, middle line not visible and other lines white  */
  .toggler:checked + .hamburger > div{
      background: rgba(0,0,0,0);
  }
  .toggler:checked + .hamburger > div::before{
      top: 0;
      transform: rotate(45deg);
      background: white;
  }
  /* ROTATING THE TOP AND BOTTOM LINES :  */
  .toggler:checked + .hamburger > div::after{
      top: 0;
      transform: rotate(135deg);
      background: white;
  }

  /* width of 200 is also set in .contentWithSidebar in app.css */
  .sideBar {
    width: 250px;
  }
  .sideBarThin {
    width: 72px;
  }
  
  .sideBar,
  .sideBarThin {
    padding: 1rem 0;
    margin: 0;
    background-color: #fff;
    position: fixed;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0.2rem 0.4rem 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    overflow: hidden;
  }

  @media screen and (max-width: 992px) {
    .sideBar {
        display: none;
    }
  }

  /* Account card on the sidebar */
  .logoutIcon {
    width: 3rem;
    text-align: center;
    font-size: 2rem;
  }

  .refreshContainer {
    background-color: var(--color-blue2);
  }
  .logOutContainer {
    background-color: var(--color-blue2);
  }

  .refreshContainer,
  .logOutContainer {
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border: none;
    position: relative;
    border-radius: 1rem;
    padding: 1rem;
    margin: 1rem;
  }
  .refreshContainer:hover,
  .logOutContainer:hover {
    color: var(--color-orange);
  }
  .refreshContainerTitle,
  .logOutContainerTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .refreshContainerDivider,
  .logOutContainerDivider{
    width: 100%;
    height: 2px;
    background-color: #fff;
  }

  .sidebarText,
  .sidebarLinks {
    color: var(--color-font-primary);
    font-size: 14px;
    padding: 0.5rem;
  }
  .sidebarLinks {
    cursor: pointer;
  }
  
  .sidebarLinks:hover {
    font-weight: 600;
    /* color: var(--color-orange); */
  }

  .iconActive,
  .iconNotActive {
    width: 3rem;
    text-align: center;
    font-size: 2rem;
  }

  .iconActive {
    color: var(--color-blue1);
  }
  .iconNotActive {
    color: var(--color-font-primary);
  }

  .expandArrowContainerThin{
    left: 23.2rem;
  }
  .expandArrowContainer {
    left: 5.5rem;
  }
  .expandArrowContainerThin,
  .expandArrowContainer {
    position: absolute;
    top: 8rem;
    z-index: 99;
    transition: all 0.2s;
  }

  .expendArrow {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding: 0.2rem 0.6rem;
    border-radius: 2rem;
    /* -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none; */
    background-color: var(--color-blue2);
    color: #fff;
    cursor: pointer;
  }

  @media screen and (max-width: 992px) {
    .expendArrow {
        display: none;
    }
  }
  