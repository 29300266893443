.container {
    display: none;
}

@media screen and (min-width: 992px) {
    .container {
        display: flex;
        justify-content: flex-end;
    }
}

.pageSizeSelectBtn,
.pageSizeSelectBtnActive {
    font-size: 12px;
    background-color: var(--color-gray);
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    margin: 0 0.5rem;
    cursor: pointer;
}

.pageSizeSelectBtn {
    background-color: var(--color-gray);
}

.pageSizeSelectBtnActive {
    background-color: var(--color-blue1);
    color: #fff;
    font-weight: 700;
}

.pageSizeSelectBtn:hover,
.pageSizeSelectBtnActive:hover {
    font-weight: 700;
}
/* .pageSizeSelectBtn:active,
.pageSizeSelectBtnActive:active {
    background-color: var(--color-orange);
} */