.pageContainer {
    display: flex;
}

.page {
    color: var(--color-blue1);
}
.page:hover {
    font-weight: 700;
}

.page,
.page_active {
    padding: 1rem;
    cursor: pointer;
    border-radius: 30px;
}

.page_active {
    font-weight: 700;
    color: var(--color-orange);
}