.testing {
    background-color: #fff;
    border: 1px solid black;
    position: absolute;
    right: 1rem;
    top: 3rem;
}

.button {
    color: white;
    background-color: var(--color-blue2);
}

.popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:rgba(0, 0, 0, 0.9);
    /* z-index: 1; */
}


/* moved to app.css */
/* .relative {
    position: relative;
} */


/* .actionContainer {
    position: absolute;
    background-color: #fff;
    top: 0.5rem;
    right: 1.5rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
    overflow: hidden;
    width: 190px;
    padding: 0.5rem 0;
} */

.actionChoice,
.actionChoiceDelete,
.actionChoiceDeleteActive {
    display: flex;
    padding: 0.8rem 2rem;
}
.actionChoice:hover,
.actionChoiceDelete:hover,
.actionChoiceDeleteActive:hover {
    font-weight: 600;
    background-color: aliceblue;
}

.actionChoiceDelete:hover {
    color: var(--color-warning);
}

.actionChoiceDeleteActive {
    color: var(--color-warning);
}

.actionIcon {
    width: 24px;
    height: 24px;
    text-align: center;
}

.actionLabel {
    margin-left: 1rem;
}

/* transaction UI container */
.transactionUIContainerThin {
    width: calc(100vw - 11.2rem);
}

.transactionUIContainer {
    width: calc(100vw - 29rem);
}
.transactionUIContainer,
.transactionUIContainerThin {
    position: absolute;
    z-index: 4;
    top: 2rem;
    min-height: 90vh;
    margin: 0 2rem;
    padding: 2rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 6px 0  rgba(0,0,0,0.2);
    transition: all 0.2s;
}

@media only screen and (max-width: 992px) {
    .transactionUIContainer {
        position: absolute;
        top: 9rem;
        width: 100vw;
        margin: 0;
    }
  }

.deleteBtn {
    display: flex;
    justify-content: center;
    margin: 0.8rem 2rem;
    padding: 0.5rem;
    border-radius: 1rem;
    cursor: pointer;
    border: none;
    background-color: var(--color-warning);
    color: #fff;
    text-align: center;
}

.pinInputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 2rem;
}

.pinInput {
    height: 30px;
    border: 1px solid var(--color-gray);
    border-radius: 1rem;
    width: 100px;
    /* margin: 0 1rem; */
    /* padding: 0 1rem; */
}

.deleteChoiceDisabled {
    display: flex;
    padding: 0.8rem 2rem;
    color: var(--color-gray);
}

.explain {
    font-size: 12px;
    padding: 0.8rem 2rem;
    display: none;
}

.deleteChoiceDisabled:hover + .explain {
    display: block;
    color: var(--color-warning);
}





.searchBtn {
    color: white;
    background-color: var(--color-blue2);
    font-weight: bold;
    padding: 1rem 2rem;
    margin: 0 0 0 2rem;
    border-radius: 10px;
    cursor: pointer;
}


/* copy of searchbar =================================*/

.searchBarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--color-gray);
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
    height: 60px;
    margin: 1rem 0;
    flex-wrap: wrap;
}

.searchInput {
    min-width: 250px;
    border: none;
    font-family: Arial, FontAwesome;
}
.clearBtn {
    color: #fff;
    background-color: var(--color-gray);
    border-radius: 5rem;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clearBtn i {
    font-size: 1.6rem;
}


.clearBtn:hover {
    font-weight: 700;
}

.clearBtn:active {
    background-color: var(--color-blue1);
}

.magnifyingGlassIcon {
    padding: 0 0.5rem;
}

@media only screen and (max-width: 992px) {
    .searchBarContainer {
        width: 100%;
    }
  }