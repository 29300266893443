.chartTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chartStyle {
    display: flex;
}

.chartBtn,
.chartBtnActive {
    font-size: 12px;
    background-color: var(--color-gray);
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    margin: 0 0.5rem;
    cursor: pointer;
}

.chartBtn {
    background-color: var(--color-gray);
}

.chartBtnActive {
    background-color: var(--color-blue1);
    color: #fff;
    font-weight: 700;
}

.chartBtn:hover,
.chartBtnActive:hover {
    font-weight: 700;
}
.chartBtn:active,
.chartBtnActive:active {
    background-color: var(--color-orange);
}