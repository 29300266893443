.sectionFooter {
    padding: 3rem 0;
    color: #fff;
    font-weight: 700;
}


.sectionHeader {
    font-size: 3.2rem;
    padding: 3rem 3rem 3rem 7rem;
    color: #fff;
    font-weight: 700;
}


.sectionHeaderDesc,
.sectionFooter {
    display: flex;
    justify-content: center;
}

.heroFooter {
    padding: 3rem 0;
}

@media screen and (max-width: 992px) {
    .sectionHeader{
        padding: 3rem;
        text-align: center;
    }
    .sectionHeaderDesc,
    .sectionFooter {
        display: flex;
        justify-content: center;
}
}

.buttonHomePage {
    padding: 1rem 2rem;
    cursor: pointer;
    border-radius: 1rem;
    width: fit-content;
    border: 2px solid #2091F9;
    background-color: #fff;
    color: #2091F9;
    font-weight: 700;
}

/* Section background */
.hero {
    background: var(--color-background);
    background: linear-gradient(180deg, rgba(115, 255, 187, 0.7) 0%,  rgba(78,196,255,0.7) 40%,  rgba(245,245,249, 1) 50%);
}

.feature {
    background: #173673;
    /* TODO:change this */
}


.heroContainer,
.featureContainer,
.aboutContainer,
.screenshotContainer {
    max-width: 1080px;
    margin: 0 auto;
}

.heroContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 5rem;
}

.featureContainer {
    padding: 10rem 0;
}

/* CSS for Hero Sections */

.heroTextContainer {
    padding: 3rem
}

.heroTitleContainer {
    padding: 2rem 0;
}

.heroTitle {
    font-size: 4rem;
    font-weight: 700;
    line-height: 4.5rem;
    color: #173673;
    /* TODO:change later^ */
}

.heroText1 {
    font-weight: 700;
    color: #173673;
    /* TODO:change later^ */
}
.heroText2 {
    color: #000;
    max-width: 370px;
}

.heroPic {
    /* border: 1px solid red; */
    width: 420px;
}

.heroPic img {
    width: 100%;
}

@media screen and (max-width: 996px) {
    .heroPic {
        width: 280px;
    }
    .heroContainer{
        flex-direction: column-reverse;
        justify-content: center;
        padding-top: 1rem;
    }
}

/* CSS for Screenshot Section */

.screenshotContainer {
    padding: 5rem 0;
}


.screenshot1,
.screenshot2,
.screenshot3 {
    display: flex;
    justify-content: center;
    z-index: 0;
}

.screenshot1 img,
.screenshot2 img,
.screenshot3 img {
    width: 90vw;
}

@media only screen and (min-width: 996px) {
    .screenshot1 {
        display: flex;
        justify-content: flex-end;
    }
    .screenshot1 img {
        max-width: 700px;
    }
    .screenshot2 {
        position: absolute;
        top: 5rem;
    }
    .screenshot3 {
        position: absolute;
        top: 25rem;
        left: 10rem;
    }
    .screenshot2 img,
    .screenshot3 img {
        max-width: 450px;
    }
}

/* CSS for About Section */

.aboutContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 3rem;
}

.priceTitle,
.aboutTitle {
    color: #173673;
    /* TODO: change this^ */
    font-size: 3.2rem;
    font-weight: 700;
    padding: 1rem 0;
}

.priceTitle {
    display: flex;
    justify-content: center;
}

.aboutText {
    max-width: 600px;
    padding: 2rem 0.5rem;
}

@media screen and (max-width: 996px) {
    .heroPic {
        width: 280px;
    }
    .aboutContainer{
        justify-content: center;
    }
}


/* CSS for <FeatureCard/> */
.featureCards {
    display: flex;
    flex-wrap: wrap;
    /* margin: 0 auto; */
    justify-content: center;
}

.featureCardContainer {
    box-shadow: 0 2px 6px 0  rgba(0,0,0,0.2);
    margin: 2rem;
    padding: 2rem;
    border-radius: 1rem;
    background-color: #fff;
    width: 300px;
    height: 300px;
}
/* .featureCardIcon{
    font-size: 3.2rem;
    background: linear-gradient(90deg, #056CF2 0%, #1D96F8 10%, #00C1F4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */
.featureCardTitle {
    font-size: 1.8rem;
    font-weight: 700;
    /* text-align: center; */
    padding: 1rem 0;
}

@media screen and (max-width: 992px) {
    .featureCardIcon,
    .featureCardTitle {
        text-align: center;
    }
}

/* CSS for <PricingCard/> */
.pricingCardsSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 3rem 0;
}

.pricingCardContainer,
.pricingCardContainerRecommended {
    box-shadow: 0 2px 6px 0  rgba(0,0,0,0.2);
    margin: 1rem;
    padding: 1.5rem;
    width: 350px;
    border-radius: 1rem;
}

.pricingCardContainer {
    background-color: #fff;
}

.pricingCardContainerRecommended {
    background-color: #c6ecff;
    position: relative;
}



.featuresContainer {
    padding: 1rem 1rem 4rem 1rem;
}

.pricingFeature {
    display: flex;
    padding: 1rem 0;
    height: 4.2rem;
}

.pricingFeature div {
    font-size: 1.2rem;
}

.planTitle {
    font-size: 2.2rem;
    font-weight: 700;
    padding: 1rem 0;
}

.planDesc {
    height: 4.2rem;
}

.priceContainer {
    margin: 3rem 1rem;
    position: relative;
    
}

.priceDesc {
    font-size: 1.2rem;
    position: absolute;
    top: 3rem;
}

.priceCombined {
    display: flex;
    align-items: flex-start;
}

.price {
    font-size: 2.5rem;
    font-weight: 500;
}

.priceLeft {
    font-size: 1.6rem;
}

.priceLeft,
.priceRight {
    line-height: 30px;
}

.tryItFree {
    cursor: pointer;
    background-color: var(--color-blue3);
    border-radius: 1rem;
    text-align: center;
    padding: 1rem;
    color: #fff;
    margin: 1rem 0;
}

.ribbon {
    position: absolute;
    background-color: #FFCC00;
    padding: 0.5rem 1rem;
    border-radius: 3rem;
    font-size: 1.1rem;
    color: #000;
    font-weight: 700;
    right: 1rem;
    top: -1rem;
}

.ribbonHide {
    display: none;
}

/* CSS for Footer section  */

.footerContainer {
    background-color: #32D98B;
    padding: 5rem 10rem;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: space-between;
    color: #000;
}

@media screen and (max-width: 992px) {
    .footerContainer {
        /* justify-content: center; */
        text-align: center;
    }
    .footerContact {
        padding-top: 5rem;
    }
}
@media screen and (max-width: 660px) {
    .footerContainer {
        justify-content: center;
    }
}

.footerTitle {
    font-weight: 700;
}

.footerTextContainer {
    padding: 1rem;
}

.footerSignUpTitle {
    font-size: 2.4rem;
    font-weight: 900;
    padding-bottom: 3rem;
}

.buttonSignUp {
    padding: 1rem 2rem;
    cursor: pointer;
    border-radius: 1rem;
    /* width: fit-content; */
    text-align: center;
    border: 2px solid #2091F9;
    background-color: #fff;
    color: #2091F9;
    font-weight: 700;
}