html {
  font-size: 62.5%;
}

div {
  font-size: 1.4rem;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Inter,sans-serif, FontAwesome;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4f5d6c;
  background-color: var(--color-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

:root {
  --color-blue1: #16425B;
  --color-blue2: #2F6690;
  --color-blue3: #3A7CA5;
  --color-blue4: #8aC3D7;
  --color-blue5: #c9e0ed;
  --color-gray: #d2d2d2;
  --color-orange: #ff6d37;
  --color-font-primary: #697a8d;
  --color-background: #f5f5f9;
  --color-warning: #ff4c4c;

  --color-ice-font: #00404D;
  --color-ice-background: #B4F1FE;
  --color-water-font: #2E4832;
  --color-water-background: #D6F5DB;
  --color-alkaline-font: #00194D;
  --color-alkaline-background: #B8CEF9;
}

.fontAwesome {
  font-family: 'Helvetica', FontAwesome, sans-serif;
}

h1, h2, h3 {
  margin: 0;
}

/* img {
  object-fit: contain;
} */

tr {
  border: 1px solid var(--color-background);
}

a {
  text-decoration: none;
}

a:link, a:visited {
  color: var(--color-font-primary)
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loader {
  border: 2.5px solid var(--color-blue4);
  border-radius: 50%;
  border-top: 2.5px solid #fff;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
.loaderDelete {
  border: 2.5px solid #ff9292;;
  border-radius: 50%;
  border-top: 2.5px solid #fff;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

select {
  padding: 0 10px;
  height: 30px;
  border-radius: 1rem;
  border: 1px solid var(--color-gray);
  margin: 0 1rem;
}